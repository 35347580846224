import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

const Employees = ({ team, children }) => {
  return (
    <section id="employees" className="section">
      <div id="team" className="container has-text-centered">
        {children}
        <div className="wrapper">
          {team.map(employee => (
            <Employee
              key={employee.name}
              name={employee.name}
              img={employee.img}
              position={employee.position}
              phone={employee.phone}
              mail={employee.mail}
            />
          ))}
        </div>
      </div>
    </section>
  )
}

const Employee = ({ name, img, position, phone, mail }) => (
  <div className="box">
    <figure className="image">
      <img src={img} alt={name} />
    </figure>
    <h3 className="subtitle">{name}</h3>
    <h5 className="subtitle">{position}</h5>
    <p>
      <FormattedMessage id="phone" /> {phone}
    </p>
    <p>
      <a href={'mailto:' + mail}>{mail}</a>
    </p>
  </div>
)

Employee.propTypes = {
  name: PropTypes.string,
  img: PropTypes.string,
  position: PropTypes.string,
  phone: PropTypes.string,
  mail: PropTypes.string,
}

Employees.propTypes = {
  children: PropTypes.element,
  team: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      img: PropTypes.string,
      position: PropTypes.string,
      phone: PropTypes.string,
      mail: PropTypes.string,
    })
  ),
}

export default Employees
