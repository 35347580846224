import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import ContactUs from '../components/ContactUs';
import Content, { HTMLContent } from '../components/Content';
import Hero from '../components/Hero';
import Employees from '../components/Employees';
import { withIntl } from '../i18n';

export const AboutPageTemplate = ({
  title,
  team,
  content,
  contentComponent
}) => {
  const PageContent = contentComponent || Content;
  return (
    <div>
      <Hero title={title} />
      <Employees team={team}>
        <PageContent content={content} />
      </Employees>
      <ContactUs />
    </div>
  );
};

AboutPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  seoDescription: PropTypes.string,
  team: PropTypes.array,
  content: PropTypes.string,
  contentComponent: PropTypes.func
};

const AboutPage = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout
      seoDescription={post.frontmatter.seoDescription}
      seoTitle={post.frontmatter.seoTitle}
    >
      <AboutPageTemplate
        title={post.frontmatter.title}
        team={post.frontmatter.team}
        contentComponent={HTMLContent}
        content={post.html}
      />
    </Layout>
  );
};

AboutPage.propTypes = {
  data: PropTypes.object.isRequired
};

export default withIntl(AboutPage);

export const aboutPageQuery = graphql`
  query AboutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        seoDescription
        seoTitle
        team {
          name
          img
          position
          phone
          mail
        }
      }
    }
  }
`;
